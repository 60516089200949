import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VNavigationDrawer,
    {
      staticClass: "navigation-drawer",
      attrs: { permanent: _vm.$vuetify.breakpoint.mdAndUp, "hide-overlay": "" },
      model: {
        value: _vm.isDrawerShownModel,
        callback: function($$v) {
          _vm.isDrawerShownModel = $$v
        },
        expression: "isDrawerShownModel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "navigation-drawer__header" },
        [
          _c(
            "div",
            { staticClass: "pl-2" },
            [
              _c(
                VAvatar,
                {
                  staticClass: "avatar",
                  attrs: { width: 96, tile: "" },
                  on: { click: _vm.redirectToLivePage }
                },
                [
                  _c(VImg, {
                    attrs: {
                      src: require("@/assets/images/astro-logo.png"),
                      contain: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isDrawerShown && _vm.isMobile
            ? _c(
                VBtn,
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.isDrawerShownModel = false
                    }
                  }
                },
                [_c(VIcon, { staticClass: "icon" }, [_vm._v("close")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        VList,
        { staticClass: "navigation-drawer__content" },
        [
          _c(
            VListItem,
            { attrs: { ripple: false, link: "", to: { name: "live" } } },
            [
              _c(
                VListItemIcon,
                [
                  _c(VIcon, { staticClass: "icon" }, [
                    _vm._v("online_prediction")
                  ])
                ],
                1
              ),
              _c(
                VListItemContent,
                { attrs: { "data-intercom-target": "live" } },
                [_vm._v(" " + _vm._s(_vm.$t("menu.live")) + " ")]
              )
            ],
            1
          ),
          _vm.isReportsViewer
            ? _c(
                VListGroup,
                {
                  class: { "list-group--active": _vm.isReportsTab },
                  attrs: { value: _vm.isReportsTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              VListItemIcon,
                              [
                                _c(VIcon, { staticClass: "icon" }, [
                                  _vm._v("bar_chart")
                                ])
                              ],
                              1
                            ),
                            _c(
                              VListItemContent,
                              { attrs: { "data-intercom-target": "reports" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("menu.reports")) + " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2755797382
                  )
                },
                [
                  _vm.isPerformanceViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: {
                            ripple: false,
                            link: "",
                            to: { name: "performances" }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            {
                              attrs: { "data-intercom-target": "performance" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.performancesTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinanceViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: {
                            ripple: false,
                            link: "",
                            to: { name: "finance" }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            { attrs: { "data-intercom-target": "finance" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.financeTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isQualityViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/quality")
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            { attrs: { "data-intercom-target": "quality" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.qualityTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinanceViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/sales")
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            { attrs: { "data-intercom-target": "items_sold" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("reportings.salesTab.label")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isRefundsViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/refunds")
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            { attrs: { "data-intercom-target": "refunds" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.refundsTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isOrdersViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl(
                                "/r/reports/orders-details"
                              )
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            {
                              attrs: {
                                "data-intercom-target": "orders_details"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.ordersDetailsTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isHygieneViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/reports/hygiene")
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            { attrs: { "data-intercom-target": "hygiene" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("reportings.hygieneTab.label")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDeliveriesPerformance
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl(
                                "/r/reports/delivery-control"
                              )
                            }
                          }
                        },
                        [
                          _c(
                            VListItemContent,
                            {
                              attrs: {
                                "data-intercom-target": "delivery-control"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "reportings.deliveryControlTab.label"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.isInvoicesViewer || _vm.hasInvoicesBeta
            ? _c(
                VListItem,
                {
                  class: { "v-list-item--active": _vm.isInvoicesTab },
                  attrs: { ripple: false, value: _vm.isInvoicesTab, link: "" },
                  on: { click: _vm.handleInvoicesRedirection }
                },
                [
                  _c(
                    VListItemIcon,
                    [
                      _c(VIcon, { staticClass: "icon" }, [
                        _vm._v("description")
                      ])
                    ],
                    1
                  ),
                  _c(VListItemContent, [
                    _vm._v(" " + _vm._s(_vm.$t("menu.invoices")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isMenuManagerViewer
            ? _c(
                VListGroup,
                {
                  class: { "list-group--active": _vm.isMenuManagerTab },
                  attrs: { value: _vm.isMenuManagerTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              VListItemIcon,
                              [
                                _c(VIcon, { staticClass: "icon" }, [
                                  _vm._v("restaurant_menu")
                                ])
                              ],
                              1
                            ),
                            _c(VListItemContent, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("menu.menuManager")) + " "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    542031277
                  )
                },
                [
                  _vm.isMenuManagerTemplatesViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: {
                            link: "",
                            ripple: false,
                            to: { path: "/menu-manager/menus" }
                          }
                        },
                        [
                          _c(VListItemContent, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("menuManager.homepage.menus.title")
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuManagerRestaurantsViewer
                    ? _c(
                        VListItem,
                        {
                          attrs: {
                            link: "",
                            ripple: false,
                            to: { path: "/menu-manager/restaurants" }
                          }
                        },
                        [
                          _c(VListItemContent, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "menuManager.homepage.restaurants.title"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasItemLibraryAccess
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: { click: _vm.handleItemLibraryRedirection }
                        },
                        [
                          _c(VListItemContent, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "menuManager.homepage.itemLibrary.title"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.hasKitchensAccess
            ? _c(
                VListItem,
                {
                  attrs: { ripple: false, link: "" },
                  on: {
                    click: function($event) {
                      return _vm.navigateToUrl("/r/kitchens")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [
                      _c(VIcon, { staticClass: "icon" }, [
                        _vm._v("location_city")
                      ])
                    ],
                    1
                  ),
                  _c(VListItemContent, [
                    _vm._v(" " + _vm._s(_vm.$t("menu.kitchens")) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isMenuManagerTemplatesViewer
            ? _c(
                VListItem,
                {
                  attrs: { link: "", ripple: false },
                  on: {
                    click: function($event) {
                      return _vm.navigateToUrl("/r/brand-manager")
                    }
                  }
                },
                [
                  _c(
                    VListItemIcon,
                    [
                      _c(VIcon, { staticClass: "icon" }, [
                        _vm._v("lunch_dining")
                      ])
                    ],
                    1
                  ),
                  _c(VListItemContent, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("menuManager.homepage.brandManager.title")
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.hasSupplyAccess
            ? _c(
                VListGroup,
                {
                  class: { "list-group--active": _vm.isSupplierTab },
                  attrs: { value: _vm.isSupplierTab, ripple: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              VListItemIcon,
                              [
                                _c(
                                  VBadge,
                                  {
                                    attrs: {
                                      color: "primary",
                                      dot: "",
                                      value:
                                        !!_vm.totalOrderingReminder ||
                                        !!_vm.totalDeliveriesReminder
                                    }
                                  },
                                  [
                                    _c(VIcon, { staticClass: "icon" }, [
                                      _vm._v("local_shipping")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              VListItemContent,
                              { attrs: { "data-intercom-target": "supply" } },
                              [_vm._v(" " + _vm._s(_vm.$t("supply")) + " ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    812844080
                  )
                },
                [
                  _vm.hasSuppliersAccess
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/suppliers")
                            }
                          }
                        },
                        [
                          _c(VListItemContent, [
                            _c("div", { staticClass: "sub-list-item" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("menu.orders")))
                              ]),
                              !!_vm.totalOrderingReminder
                                ? _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.totalOrderingReminder > 99
                                            ? "99+"
                                            : _vm.totalOrderingReminder
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasSupplyAccess
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/orders")
                            }
                          }
                        },
                        [
                          _c(VListItemContent, [
                            _c("div", { staticClass: "sub-list-item" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("menu.deliveries")))
                              ]),
                              !!_vm.totalDeliveriesReminder
                                ? _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.totalDeliveriesReminder > 99
                                            ? "99+"
                                            : _vm.totalDeliveriesReminder
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasSupplyMailbox
                    ? _c(
                        VListItem,
                        {
                          attrs: { ripple: false, link: "" },
                          on: {
                            click: function($event) {
                              return _vm.navigateToUrl("/r/mailbox")
                            }
                          }
                        },
                        [
                          _c(VListItemContent, [
                            _vm._v(" " + _vm._s(_vm.$t("mailbox")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "navigation-drawer__footer" },
        [
          _c(
            VBtn,
            {
              staticClass: "support-btn",
              attrs: { id: "support", ripple: false, text: "", block: "" }
            },
            [
              _c(VIcon, { staticClass: "icon" }, [
                _vm._v("contact_support")
              ]),
              _c("div", [_vm._v("Support")])
            ],
            1
          ),
          _c(
            "a",
            {
              attrs: { href: _vm.localizeDocumentationLink, target: "_blank" }
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "support-btn",
                  attrs: { ripple: false, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleDocumentationClick()
                    }
                  }
                },
                [
                  _c(VIcon, { staticClass: "icon auto_stories" }),
                  _c("div", [_vm._v(_vm._s(_vm.$t("shared.seeDocumentation")))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c(VIcon, { attrs: { size: "30" } }, [
                _vm._v("account_circle")
              ]),
              _c("p", { staticClass: "user-email" }, [
                _vm._v(" " + _vm._s(_vm.userEmail) + " ")
              ]),
              _c("LogoutButton")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }