import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-layout" },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            VAppBar,
            {
              attrs: { elevation: "0", fixed: "", "clipped-left": "", app: "" }
            },
            [
              _c(
                VAppBarNavIcon,
                { on: { click: _vm.toggleDrawer } },
                [_c(VIcon, [_vm._v("menu")])],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "page-layout-wrapper" },
        [
          _c("SideBarNavigation", {
            attrs: { "is-drawer-shown": _vm.isDrawerShown },
            on: { "update:isDrawerShown": _vm.setIsDrawerShown }
          }),
          _c(VMain, [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }