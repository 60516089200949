import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PageLayout", [
    _c(
      "div",
      { staticClass: "unauthorized" },
      [
        _c("div", { staticClass: "emoji-sad" }, [_vm._v("😥")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.$t("shared.unauthorized")) + " ")]),
        _c(VBtn, { on: { click: _vm.logout } }, [
          _vm._v(" " + _vm._s(_vm.$t("shared.logout")) + " ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }